import axios from "axios";
import { API_URLS } from "../../config";

function uploadMedia(formData) {
  return axios.post(`${API_URLS.BLOB_STORAGE.UPLOAD_ATTACHMENT}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export { uploadMedia };
