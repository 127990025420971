import PropTypes from "prop-types";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";

export default function GenericModal({ children, ...props }) {
  useEffect(() => {
    const content = document.getElementById("app-content");

    if (content) {
      if (props.show) {
        content.classList.add("modal-blur-background");
      } else {
        content.classList.remove("modal-blur-background");
      }
    }

    return () => {
      if (content) {
        content.classList.remove("modal-blur-background");
      }
    };
  }, [props.show]);
  return (
    <Modal
      size={props.size}
      show={props.show}
      onHide={props.onHide}
      centered={props.centered}
      className={props.className}
      aria-labelledby="modal"
      backdrop="static"
    >
      <Modal.Body className="p-0">{children}</Modal.Body>
    </Modal>
  );
}

GenericModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  centered: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};

GenericModal.defaultProps = {
  centered: true,
  size: "md",
  className: "",
  title: "",
  children: null,
};
