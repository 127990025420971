// import { FaArrowLeft } from "react-icons/fa";
import { IMAGES } from "../../Assets";
import { Box, Button, Typography } from "../../Components";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATH } from "../../config";
import { FaArrowLeft } from "react-icons/fa";
import useWindowSizeHook from "../../Components/WindowSizeHook";

export default function ThankYou() {
  const navigate = useNavigate();
  const { width } = useWindowSizeHook();

  const navigateFun = (url) => {
    window.location.href = url;
  };
  return (
    <Box
      width="100%"
      maxWidth="202rem"
      minHeight="50rem"
      borderRadius="1.375rem"
      background="linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.70) 100%)"
      shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
      backdropFilter="blur(15px)"
      className="p-5"
    >
      <div>
        <span
          className="text-primary fw-bold d-flex align-items-center justify-content-start  cursor-pointer mb-3"
          onClick={() => navigate(ROUTER_PATH.CHAT_HISTORY)}
        >
          <FaArrowLeft className="me-2" /> Chat History
        </span>
      </div>

      <div className="d-flex flex-column justify-content-center align-items-center">
        <img src={IMAGES.THANKYOU_ICON} alt="" width={width < 600 ? "100" : "300"} />
        <Typography as={width < 600 ? "h6" : "h1"} className="text-center">
          Thank you!
        </Typography>
      </div>
      <Typography as={width < 600 ? "label" : "h5"} className="text-center">
        For using MediStreamline
      </Typography>
      <div className="d-flex mt-3 justify-content-center align-items-center">
        <Button
          onClick={() =>
            navigateFun("https://form.typeform.com/to/CeQtkWw1")
            // navigateFun("https://viwnilw91ea.typeform.com/medistreamline")
          }
          className="text-white "
        >
          Give Feedback
        </Button>
      </div>
    </Box>
  );
}
