import { Tab, Tabs } from "react-bootstrap";
import {
	Box,
	Button,
	GenericLoader,
	Toaster,
	Typography,
} from "../../Components";
import IndividualSession from "./individualSession";
import Report from "./report";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMessage } from "../../Redux/actions/messageActions";
import { FaArrowLeft } from "react-icons/fa";
import { ROUTER_PATH } from "../../config";
import { generateChatReportAction } from "../../Redux/actions";

function ChatDetails() {
	const location = useLocation();
	let dispatch = useDispatch();
	const navigate = useNavigate();

	const messagesList = useSelector((state) => state.messages);
	const checkChatReport = useSelector((state) => state.chat);
	const { messages } = location.state || {};
	console.log("location ", messages, messagesList, checkChatReport);

	const [key, setKey] = useState("individualChat");

	useEffect(() => {
		if (messages?.chatId) {
			dispatch(getMessage(messages?.chatId));
		}
	}, [dispatch, messages]);

	function genrateUserChatReport(chatId) {
		let finalData = {
			chat_id: chatId,
		};
		dispatch(generateChatReportAction(finalData, moveToNext));
	}

	function moveToNext(response) {
		if (response?.status === 200) {
			setKey("report");
			Toaster({
				message: response.data.message,
				type: "success",
			});
		} else {
			Toaster({
				message: response.data.message,
				type: "error",
			});
		}
	}

	return (
		<Box
			width="100%"
			maxWidth="202rem"
			borderRadius="1.375rem"
			background="linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.70) 100%)"
			shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
			backdropFilter="blur(15px)"
			className="p-5 m-auto history-wrapper"
		>
			<div className="d-flex align-items-center justify-content-between">
				{/* <Typography as="h5" color="#2E3130"> */}
				<span
					className="d-flex align-items-center fw-bold text-primary cursor-pointer mb-3"
					onClick={() => navigate(ROUTER_PATH.CHAT_HISTORY)}
				>
					<FaArrowLeft className="me-2" /> Back
				</span>
				{messagesList?.MESSAGE?.[0]?.reportTypeId === 301 &&
					!checkChatReport?.GENERATE_CHAT_REPORT && (
						<Button
							height="36px"
							type="button"
							disabled={checkChatReport?.GENERATE_CHAT_REPORT_LOADING}
							onClick={() => genrateUserChatReport(messages?.chatId)}
						>
							<Typography
								align="center"
								fontSize="0.9rem"
								fontWeight="500"
								color="#fff"
							>
								{checkChatReport?.GENERATE_CHAT_REPORT_LOADING ? (
									<div className="text-center">
										<GenericLoader />
									</div>
								) : (
									" Generate Report"
								)}
							</Typography>
						</Button>
					)}
			</div>
			<Tabs
				defaultActiveKey="individualChat"
				id="uncontrolled-tab-example"
				className="mb-3"
				activeKey={key}
				onSelect={(k) => setKey(k)}
			>
				<Tab eventKey="individualChat" title="Chat History">
					<IndividualSession
						messages={messages}
						setKey={setKey}
						messagesList={messagesList}
						checkChatReport={checkChatReport}
					/>
				</Tab>
				{(messagesList?.MESSAGE?.[0]?.reportTypeId === 302 ||
					checkChatReport?.GENERATE_CHAT_REPORT) && (
					<Tab eventKey="report" title="Report">
						<Report
							messagesList={messagesList}
							checkChatReport={checkChatReport}
						/>
					</Tab>
				)}
			</Tabs>
		</Box>
	);
}

export default ChatDetails;
