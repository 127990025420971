/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Box, GenericLoader, Typography } from "../../Components";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATH } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { getChatByUsers, getDoctorProfile } from "../../Redux/actions";
import { getLoggedInUser } from "../../utils/helper";
import { FiEye } from "react-icons/fi";
import moment from "moment";
import { FaArrowLeft } from "react-icons/fa";
import useWindowSizeHook from "../../Components/WindowSizeHook";

const COLUMN_BOX_PROPS = {
	background: "#ffffff",
	borderRadius: "0.75rem",
	width: "100%",
	height: "100%",
	maxWidth: "202rem",
};
const ChatHistory = () => {
	const navigate = useNavigate();
	const { width: useWidth } = useWindowSizeHook();

	const dispatch = useDispatch();

	const medUser = getLoggedInUser();
	const chat = useSelector((state) => state.chat);

	console.log("chat?.CHAT", chat?.CHAT);
	useEffect(() => {
		if (!chat?.DOCTOR_PROFILE?.data[0]?.doctorId)
			dispatch(getDoctorProfile({ isSystemDoctor: true }));
	}, [chat?.DOCTOR_PROFILE?.data, dispatch]);

	useEffect(() => {
		if (medUser?.userId && chat?.DOCTOR_PROFILE?.data[0]?.doctorId) {
			dispatch(
				getChatByUsers({
					pageNo: 1,
					size: 20,
					search: null,
					isPagination: true,
					sortBy: null,
					sortDirection: null,
					fromUserId: medUser?.userId,
					toUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
					isSessionEnd: true,
				})
			);
		}
	}, [chat?.DOCTOR_PROFILE?.data, medUser?.userId]);

	useEffect(() => {
		if (chat?.GENERATE_CHAT_REPORT) {
			dispatch(resetGenerateChatReport());
		}
	}, [dispatch, chat?.GENERATE_CHAT_REPORT]);
	return (
		<Box
			width="100%"
			maxWidth="202rem"
			height="calc(100vh - 130px)"
			borderRadius="1.375rem"
			background="linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.70) 100%)"
			shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
			backdropFilter="blur(15px)"
			className={` ${
				useWidth < 600 ? "p-3 m-auto overflow-auto" : "p-5 m-auto overflow-auto"
			}`}
		>
			<span
				className="d-flex align-items-center fw-bold text-primary cursor-pointer mb-3"
				onClick={() => navigate(ROUTER_PATH.HOME_PAGE)}
			>
				<FaArrowLeft className="me-2" /> Back
			</span>
			<Typography as={useWidth < 600 ? "h6" : "h5"} align="left">
				Chat History
			</Typography>

			<Row>
				{chat?.GET_CHAT_HISTORY_IS_LOADING ? (
					<div className="my-5 d-flex align-items-center justify-content-center">
						<GenericLoader />
					</div>
				) : chat?.CHAT?.length < 1 ? (
					<Typography as="p" align="center" className="my-5">
						No Chat Found
					</Typography>
				) : (
					chat?.CHAT?.map((item, idx) => (
						<Col xs={12} lg={6} key={idx}>
							<Box
								{...COLUMN_BOX_PROPS}
								className="mt-3 px-4 pt-3 pb-2 cursor-pointer"
								onClick={() =>
									navigate(ROUTER_PATH.CHAT_DETAILS, {
										state: { messages: item },
									})
								}
								// onClick={() => navigate(ROUTER_PATH.INDIVIDUAL_SESSION)}
							>
								<Typography
									as={useWidth < 600 ? "" : "h6"}
									fontSize="20px"
									fontWeight="600"
									color="#2E3130"
								>
									{item?.name || "General"}
									{/* General */}
								</Typography>
								<Row className="py-3">
									<Col
										xs={12}
										md={6}
										className="d-flex align-items-center mb-2"
									>
										<Typography as="p" color="#636967" className="me-2">
											History ID:
										</Typography>
										<Typography as="p" color="#4A4F4D">
											{item?.historyId}
										</Typography>
									</Col>
									<Col
										xs={12}
										md={6}
										className="d-flex align-items-center mb-2"
									>
										<Typography as="p" color="#636967" className="me-2">
											Chat type:
										</Typography>
										<Typography as="p" color="#4A4F4D">
											{item?.chatType ? item?.chatType : "N/A"}
										</Typography>
									</Col>
									<Col
										xs={12}
										md={6}
										className="d-flex align-items-center mb-2"
									>
										<Typography as="p" color="#636967" className="me-2">
											Date:
										</Typography>
										<Typography as="p" color="#4A4F4D">
											{moment(item?.createdDate).format(
												"MMMM Do YYYY, h:mm:ss a"
											)}
										</Typography>
									</Col>
									<Col
										xs={12}
										md={6}
										className="d-flex align-items-center mb-2"
									>
										<Typography as="p" color="#636967" className="me-2">
											Report type:
										</Typography>
										<Box
											borderRadius="0.25rem"
											background={
												item?.reportType === "NotGenerated"
													? "#e8e8e8"
													: "#e8ffea"
											}
											className="p-2 m-auto"
											// onClick={(event) => {
											//   event.stopPropagation();
											//   navigate(ROUTER_PATH.REPORT);
											// }}
										>
											<Typography
												fontWeight={500}
												fontSize={12}
												color={
													item?.reportType === "NotGenerated"
														? "#757575"
														: "#00810b"
												}
												className="gen-btn"
											>
												{item?.reportType}{" "}
												{item?.reportType === "Generated" && (
													<FiEye className="ms-1" size={18} />
												)}
											</Typography>
										</Box>
									</Col>
								</Row>
							</Box>
						</Col>
					))
				)}
			</Row>
		</Box>
	);
};

export default ChatHistory;

export const resetGenerateChatReport = () => ({
	type: "RESET_GENERATE_CHAT_REPORT",
});
